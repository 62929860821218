import React from 'react';
import NeptuneLogoWhite from '../../../assets/images/Neptune/forwarding-logo-white.png';
import './Navbar.css';
import $ from 'jquery';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
    return (
        <div>
            <nav className="mobile-nav d-lg-none" align="left">                
                <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/" className="nav-link d-block d-lg-none pb-5 navClose">
                    <img src={NeptuneLogoWhite} alt="neptune_logo" className="img-fluid"/>
                </NavLink>

                <ul className="float-right">
                    <li>
                        <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/" className="nav-link navClose" >
                            Home
                        </NavLink>    
                    </li>
                    <li>
                        <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/about" className="nav-link navClose" >
                            About Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/download" className="nav-link navClose" >
                            Download Agency List
                        </NavLink>
                    </li>
                    <li><a href="https://intranet.neptunecargonetwork.com" rel="noopener noreferrer" target="_blank">Member Login</a></li>
                    <li><a href="https://neptunecargonetwork.com/post-event-2019/" rel="noopener noreferrer" target="_blank">Post-event Summary</a></li>
                    <li>
                        <NavLink exact activeStyle={{ color: '#00bcf3 !important', backgroundColor: '#057294' }} to="/contact" className="nav-link btn contact-button btn-md navClose" >
                        <i className="fas fa-phone"></i>&nbsp; 
                            Contact Us
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact activeStyle={{ color: '#00bcf3 !important', backgroundColor: '#205083' }} to="/applyhere" className="nav-link btn apply-button btn-md navClose" >
                            <i className="fas fa-users"></i>&nbsp;
                            Apply here
                        </NavLink>
                    </li>
                    <li>
                        <a className="btn reg-button btn-md navClose" href="#footer">
                            <i className="fas fa-edit"></i>&nbsp; 
                            Register to our event
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default Sidebar;
