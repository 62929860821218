import React, {Component} from 'react';
import NeptuneLogo from '../../../assets/images/Neptune/forwarding-logo.png';
import NeptuneLogoWhite from '../../../assets/images/Neptune/forwarding-logo-white.png';
import './Navbar.css';
import $ from 'jquery';

import { NavLink } from 'react-router-dom';

export class Navbar extends Component {
    componentDidMount() {
   
        "use strict";
        
        // Mobile Navigation
        if ($('.main-nav').length) {
           
        //   var $mobile_nav = $('.main-nav').clone().prop({
        //     class: 'mobile-nav '
        //   });
        //   $('body').append($mobile_nav);
          $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="fa fa-bars"></i></button>');
          $('body').append('<div class="mobile-nav-overly"></div>');
          
        
          $(document).on('click', '.mobile-nav-toggle', function(e) {
            $('body').toggleClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
            $('.mobile-nav-overly').toggle();
          });
          
          $(document).on('click', '.mobile-nav .drop-down > a', function(e) {
            e.preventDefault();
            $(this).next().slideToggle(300);
            $(this).parent().toggleClass('active');
          });
      
          $(document).click(function(e) {
            var container = $(".mobile-nav, .mobile-nav-toggle");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
              if ($('body').hasClass('mobile-nav-active')) {
                $('body').removeClass('mobile-nav-active');
                $('.mobile-nav-toggle i').toggleClass('fa-times fa-bars');
                $('.mobile-nav-overly').fadeOut();
              }
            }
          });

            $('.navClose').on('click', function(){
                $('body').removeClass('mobile-nav-active');
                $('.mobile-nav-toggle i').toggleClass('fa-bars fa-times');
                $('.mobile-nav-overly').fadeOut();
            });

        //   resize windows for sidebar
            $(window).resize(function() {
                if ($(window).width() <= 1170) {
                    $('.main-nav').removeClass('d-lg-block');
                    $('.mobile-nav-toggle').removeClass('d-lg-none'); 
                    $('.mobile-nav').removeClass('d-lg-none');

                    // if( $('.mobile-nav-overly').hasClass('d-lg-none')) {
                    //     $('.mobile-nav-active').css({overflow: 'hidden'});  
                    // }                     
                }
                else
                {
                    $('.main-nav').addClass('d-lg-block');
                    $('.mobile-nav-toggle').addClass('d-lg-none'); 
                    $('.mobile-nav').addClass('d-lg-none'); 
                    $('.mobile-nav-overly').addClass('d-lg-none');  
                    $('.mobile-nav-active').css({overflow: 'scroll!important'});                             
                }
            });

            

            if ($(window).width() <= 1170) {
                $('.main-nav').removeClass('d-lg-block');
                $('.mobile-nav-toggle').removeClass('d-lg-none'); 
                $('.mobile-nav').removeClass('d-lg-none');   
                   
                // if( $('.mobile-nav-overly').hasClass('d-lg-none')) {
                //     $('.mobile-nav-active').css({overflow: 'hidden'});  
                // }
            }
            else
            {
                $('.main-nav').addClass('d-lg-block');
                $('.mobile-nav-toggle').addClass('d-lg-none'); 
                $('.mobile-nav').addClass('d-lg-none'); 
                $('.mobile-nav-overly').addClass('d-lg-none');  
                $('.mobile-nav-active').css({overflow: 'scroll!important'}); 
            }


        } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
          $(".mobile-nav, .mobile-nav-toggle").hide();
        }
         
    }
    render(){
        
        return (
            <div>               
                <header className="header_area" id="header">                    
                    <div className="logo float-left">
                        <NavLink exact  activeStyle={{ color: '#00bcf3' }} to="/" className="nav-link" >
                            <img src={NeptuneLogo} alt="neptune_logo" className="img-fluid"/>
                        </NavLink>                        
                    </div>

                    <nav className="main-nav d-none d-lg-block">
                        
                        <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/" className="nav-link d-block d-lg-none pb-5">
                            <img src={NeptuneLogoWhite} alt="neptune_logo" className="img-fluid"/>
                        </NavLink>

                        <ul className="float-right">
                            <li>
                                <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/" className="nav-link" >
                                    Home
                                </NavLink>    
                            </li>
                            <li>
                                <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/about" className="nav-link" >
                                    About Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact activeStyle={{ color: '#00bcf3' }} to="/download" className="nav-link" >
                                    Download Agency List
                                </NavLink>
                            </li>
                            <li><a href="https://intranet.neptunecargonetwork.com" rel="noopener noreferrer" target="_blank">Member Login</a></li>
                            <li><a href="https://neptunecargonetwork.com/post-event-2019/" rel="noopener noreferrer" target="_blank">Post-event Summary</a></li>
                            <li>
                                <NavLink exact activeStyle={{ color: '#00bcf3 !important', backgroundColor: '#057294' }} to="/contact" className="nav-link btn contact-button btn-md" >
                                <i className="fas fa-phone"></i>&nbsp; 
                                    Contact Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact activeStyle={{ color: '#00bcf3 !important', backgroundColor: '#205083' }} to="/applyhere" className="nav-link btn apply-button btn-md" >
                                    <i className="fas fa-users"></i>&nbsp;
                                    Apply here
                                </NavLink>
                            </li>
                            <li>
                                <a className="btn reg-button btn-md" href="#footer">
                                    <i className="fas fa-edit"></i>&nbsp; 
                                    Register to our event
                                </a>
                            </li>
                        </ul>
                    </nav>
                </header>

                
                
            </div>
        );
    }
}

export default Navbar;
