import React from 'react';
import ContentLoader from "react-content-loader";
import { DisappearedLoading  } from 'react-loadingg';

const Spinner = () => {
    return (
        <div>   
             
            <ContentLoader height={160} width={400} >                
            </ContentLoader>
            <DisappearedLoading />
        </div>        
    );
}  

export default Spinner;
