import React, { Component } from 'react';
import './Footer.css';
import FooterLogo from '../../../assets/images/Neptune/gray-logo.png';
import Map from '../../../assets/images/Neptune/rw-map.png';
import ScollTop from '../scroll-top/index';

export default class Footer extends Component {
    componentDidMount() {        
        (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); 
            js.id = id;
            js.src = "//forms.aweber.com/form/52/405828252.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, "script", "aweber-wjs-z1rnt0cw8"));      
    }
    render() {
    return (
        <div>
            <section style={{backgroundColor:'#2c2c2c'}} className="footer-section">
                <div className="container white-text pt-80 pb-60" style={{backgroundColor:'#2c2c2c'}}>
                    <div className="row align-items-center">
                        <div className="col-6 col-md-6" align="left">
                            <img src={FooterLogo} style={{width:'200px'}} className="img-fluid"/>
                        </div>
                        <div className="col-6 col-md-6" align="right"> 
                           

                            <ul className="list-inline social-buttons flex-center">
                                <li className="social-icons">
                                    <a href="https://www.youtube.com/channel/UCkFW0UeMwImnEflIuCe9YXg/featured">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </li>
                                <li className="social-icons">
                                    <a href="https://www.facebook.com/NeptuneCargo/">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li className="social-icons">
                                    <a href="https://www.instagram.com/neptune_cargo_network/">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li className="social-icons">
                                    <a href="https://wa.me/63915 074 9227">
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                </li>
                                <li className="social-icons">
                                    <a href="https://www.linkedin.com/company/neptune-cargo/">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                    <hr style={{borderTop:'1px solid #868484'}}/>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="pb-20"><strong>About us</strong></div>
                            <p>
                                Neptune Forwarding is dedicated to partnering with sea freight forwarders. Its powerful and highly
                                specialized global network comprises only the best local independent sea freight consolidators from
                                their region.
                            </p>
                        </div>
                        <div className="col-sm-4 offset-md-1 col-12" align="left">
                            <div className="pb-20"><strong>Contact us</strong></div>
                            <p>
                                RWSolutions Ltd. Unit 1406 14th Floor, <br/>The Broadway,
                                54-62 Lockhart Road, Wan Chai<br/>
                                Hong Kong
                            </p><br/>
                            <p>Email: info@neptunecargonetwork.com</p>
                        </div>
                        <div className="col-sm-3 col-6" align="left">
                            <img src={Map} className="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div className="container-fluid white-text" style={{backgroundColor:'black'}}>
                    <div className="row">
                        <div className="col-12 pt-20 pb-20" align="center">
                            &copy; 2019 copyright. Neptune Forwarding. All rights reserved.
                        </div>
                    </div>
                </div>
            </section>

            
            <ScollTop/>
                
            
        </div>
    );
}
}

