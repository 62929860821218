import React, {Component, Suspense}from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import Header from './components/layout/navbar/Navbar';
import Sidebar from './components/layout/navbar/Sidebar';
import Footer from './components/layout/footer/Footer';
import Spinner from './components/layout/Spinner';
import Router from './components/layout/Router';
import './App.css';

export class App extends Component {    
  render() {
    return (      
        <div className="App">
          <BrowserRouter>
            <Header />
            <Sidebar/>
            <ScrollToTop>
              <Suspense fallback={<Spinner/>}>                  
                <Router/>
              </Suspense>            
            </ScrollToTop>
            <Footer />
          </BrowserRouter>
        </div>      
    )
  }
}

export default App

