import React, {Component, lazy} from 'react'
import { Switch, Route} from 'react-router-dom';

const Home = lazy(() => import('../../components/pages/Home/Home'));
const About = lazy(() => import('../../components/pages/AboutUs/AboutUs'));
const DownloadAgency = lazy(() => import('../../components/pages/DownloadAgency/DownloadAgency'));
const ContactUs = lazy(() => import('../../components/pages/ContactUs/ContactUs'));
const ApplyHere = lazy(() => import('../../components/pages/Apply/Index'));

export class Router extends Component {
    render() {
        return (
            <div>
                <Switch>                
                    <Route active={window.location === '/'} exact path={'/'} component = {Home} />
                    <Route active={window.location === '/about'} path={'/about'} component = {About} />         
                    <Route active={window.location === '/download'} path={'/download'} component = {DownloadAgency} /> 
                    <Route active={window.location === '/contact'} path={'/contact'} component = {ContactUs} />         
                    <Route active={window.location === '/applyhere'} path={'/applyhere'} component = {ApplyHere} />                          
                </Switch> 
            </div>
        )
    }
}

export default Router

